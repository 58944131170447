<template>
  <v-dialog
    v-model="showDialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    @keydown.esc.stop="fecharDialogDetalhamento"
  >
    <v-card>
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="fecharDialogDetalhamento">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Pagamentos Conciliados</v-toolbar-title>
        <v-spacer />

        <v-btn
          v-if="pagamentoConciliado.statusConciliacao === 'CONFIRMACAO_PENDENTE'"
          class="mr-3"
          color="primary"
          @click="abrirDialogConfirmarConciliacao"
        >
          Confirmar Conciliação
        </v-btn>

        <v-btn
          v-if="pagamentoConciliado.statusConciliacao === 'CONFIRMACAO_PENDENTE'"
          color="primary"
          @click="dialogDeletePagamentoConciliado = true"
        >
          Excluir Conciliação
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row class="pt-5">
          <v-col sm="6" md="2">
            <v-text-field
              v-model="pagamentoDetalhamentoObject.transactionId"
              label="Código Pagamento"
              disabled
            />
          </v-col>

          <v-col sm="6" md="4">
            <v-text-field
              v-model="pagamentoDetalhamentoObject.nomeCliente"
              label="Nome Cliente"
              disabled
            />
          </v-col>

          <v-col sm="6" md="4">
            <v-text-field
              v-model="pagamentoDetalhamentoObject.emailCliente"
              label="Email Cliente"
              disabled
            />
          </v-col>

          <v-col sm="6" md="2">
            <v-text-field
              v-model="pagamentoDetalhamentoObject.dataHoraPagamento"
              label="Data Hora Pagamento"
              disabled
            />
          </v-col>

          <v-col sm="6" md="2">
            <v-text-field
              v-model="pagamentoDetalhamentoObject.dataVenda"
              label="Data Venda"
              disabled
            />
          </v-col>

          <v-col sm="6" md="2">
            <v-text-field
              v-model="pagamentoDetalhamentoObject.valorPagamento"
              label="Valor Pagamento"
              disabled
            />
          </v-col>

          <v-col sm="6" md="2">
            <v-text-field
              v-model="pagamentoDetalhamentoObject.valorTaxa"
              label="Valor Taxa"
              disabled
            />
          </v-col>

          <v-col sm="6" md="3">
            <v-text-field
              v-model="pagamentoDetalhamentoObject.eventoTransacao"
              label="Tipo Pagamento"
              disabled
            />
          </v-col>

          <v-col sm="6" md="3">
            <v-text-field v-model="pagamentoDetalhamentoObject.parcela" label="Parcela" disabled />
          </v-col>
        </v-row>

        <v-data-table
          :headers="headersTitulosConciliados"
          :items="titulos"
          locale="pt-BR"
          class="elevation-1 mt-10"
          multi-sort
        >
          <template #top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Títulos</v-toolbar-title>
            </v-toolbar>
          </template>

          <template #[`body.append`]="{ headers }">
            <tr>
              <td :colspan="headers.length">
                <div class="d-flex justify-space-around">
                  <strong>
                    Total Títulos:
                    {{
                      sumValorTitulo.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      })
                    }}
                  </strong>
                </div>
              </td>
            </tr>
          </template>

          <template #[`item.dataEmissaoTitulo`]="{ item }">
            {{ formatDate(item.dataEmissaoTitulo) }}
          </template>

          <template #[`item.dataVencimentoTitulo`]="{ item }">
            {{ formatDate(item.dataVencimentoTitulo) }}
          </template>

          <template #[`item.valorTitulo`]="{ item }">
            {{ item.valorTitulo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
          </template>

          <template #[`item.acoes`]="{ item }">
            <div class="d-flex flex-nowrap justify-center">
              <v-tooltip
                v-if="pagamentoConciliado.statusConciliacao === 'CONFIRMACAO_PENDENTE'"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" @click="deletarConciliacao(item)">
                    mdi-delete
                  </v-icon>
                </template>

                <span> Deletar </span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialogDeletePagamentoConciliado" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">Excluir</v-card-title>

        <v-card-text>
          Ao remover este título está conciliação será desfeita. O pagamento e este título, que
          estão vinculados, voltarão para a tela de conciliação. Deseja prosseguir?
        </v-card-text>

        <v-card-text>
          <v-alert v-if="showMessageError" dense outlined type="error">
            {{ messageError }}
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="red darken-1" text @click="fecharDialogDeletePagamentoConciliado()">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" text @click="executarExclusaoPagamentoConciliado()">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirmarConciliacao" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">Confirmar Conciliação</v-card-title>

        <v-card-text>
          Ao confirmar as conciliações não será possivel alterar ou deletar as mesmas. Deseja
          prosseguir?
        </v-card-text>

        <v-card-text>
          <v-alert v-if="showMessageError" dense outlined type="error">
            {{ messageError }}
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="red darken-1" text @click="fecharDialogConfirmarConciliacao()">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" text @click="confirmarRelacionamentos"> Confirmar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackbarMessage
      :show-snack.sync="snackbar.showSnackParam"
      :snack-text="snackbar.snackTextParam"
      :snack-type="snackbar.snackTypeParam"
    />
  </v-dialog>
</template>

<script>
import PagamentoService from '@/services/pagamento/pagamento'
import SnackbarMessage from '@/components/ui/SnackbarMessage'
import Snackbar from '@/models/ui/snackbar'
import { formatDateTime, formatDate } from '@/shareds/formatacoes'

export default {
  components: {
    SnackbarMessage
  },

  props: ['showDialogDetalhamento', 'pagamentoConciliado'],

  data: () => ({
    titulos: [],
    sumValorTitulo: 0,
    headersTitulosConciliados: [
      { text: 'Adquirente', value: 'adquirente' },
      { text: 'Codigo Autorização', value: 'codigoAutorizacaoAdquirente' },
      { text: 'Empresa', value: 'empresa.nome' },
      { text: 'Data Emissão', value: 'dataEmissaoTitulo' },
      { text: 'Data Vencimento', value: 'dataVencimentoTitulo' },
      { text: 'Codigo Título', value: 'codigoTitulo' },
      { text: 'Parcela Título', value: 'parcelaTitulo' },
      { text: 'Valor Título', value: 'valorTitulo' },
      { text: '', value: 'acoes', width: '1%', sortable: false }
    ],
    showDialog: false,
    snackbar: new Snackbar(),
    messageError: '',
    overlay: false,
    showMessageError: false,
    dialogDeletePagamentoConciliado: false,
    dialogConfirmarConciliacao: false,
    pagamentoConciliadoConfirmacao: {},
    pagamentoConciliadoId: {},
    pagamentoDetalhamentoObject: {
      transactionId: '',
      nomeCliente: '',
      EmailCliente: '',
      valorPagamento: '',
      valorTaxa: '',
      dataHoraPagamento: '',
      dataVenda: '',
      codigoEventoTransacao: '',
      eventoTransacao: '',
      parcela: ''
    },
    formatDate: formatDate
  }),

  watch: {
    showDialogDetalhamento() {
      return this.showDialogDetalhamento ? this.buscarInformacoes() : ' '
    }
  },

  methods: {
    formatarTipoPagamento(codigo) {
      switch (codigo) {
        case 'T0006':
          return 'T0006 - PAGAMENTO DIRETO'

        case 'T0114':
          return 'T0114 - TAXA DISPUTA'

        case 'T0400':
          return 'T0400 - RETIRADA GERAL DA CONTA PAYPAL'

        case 'T1107':
          return 'T1107 - REEMBOLSO PAGAMENTO, INICIADO PELO COMERCIANTE'

        case 'T1110':
          return 'T1110 - RETENÇÃO PARA INVESTIGAÇÃO DE DISPUTA'

        case 'T1111':
          return 'T1111 - CANCELAMENTO DA RETENÇÃO PARA RESOLUÇÃO DE DISPUTAS'

        case 'T1201':
          return 'T1201 - ESTORNO'

        case 'T1202':
          return 'T1202 - ESTORNO REVERTIDO'

        case 'T1900':
          return 'T1900 - AJUSTE GERAL SEM EVENTO RELACIONADO A NEGÓCIOS'

        default:
          return ''
      }
    },

    buscarInformacoes() {
      this.showDialog = true

      this.buscarTitulos()

      this.pagamentoDetalhamentoObject = {
        ...this.pagamentoConciliado.pagamento,
        dataHoraPagamento: formatDateTime(this.pagamentoConciliado.pagamento.dataHoraPagamento),
        valorPagamento: this.pagamentoConciliado.pagamento.valorPagamento.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        }),
        valorTaxa: this.pagamentoConciliado.pagamento.valorTaxa.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        }),
        eventoTransacao: this.formatarTipoPagamento(
          this.pagamentoConciliado.pagamento.codigoEventoTransacao
        ),
        dataVenda: this.formatarDataVenda(this.pagamentoConciliado.pagamento.dataVenda)
      }
    },

    async buscarTitulos() {
      try {
        const response = await PagamentoService.buscarTitulosConciliados(
          this.pagamentoConciliado.pagamento.id
        )

        this.titulos = response

        this.sumValorTitulo = this.titulos
          .map((titulo) => titulo.valorTitulo)
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
      } catch (erro) {
        this.snackbar.snackTextParam = `${erro.response.status} | ${
          erro.response.data.message === undefined
            ? erro.message
            : erro.response.data.message
            ? erro.response.data.message
            : 'Ocorreu um erro ao buscar os títulos'
        }`
        this.snackbar.snackTypeParam = 'error'
        this.snackbar.showSnackParam = true
      } finally {
        this.buscando = false
      }
    },

    formatarDataVenda(dataVendaString) {
      let dataVenda = new Date(dataVendaString)
      return `${dataVenda.getDate().toString().padStart(2, '0')}-${
        dataVenda.getMonth() + 1
      }-${dataVenda.getFullYear()}`
    },

    deletarConciliacao(item) {
      PagamentoService.deletarConciliacao(this.pagamentoConciliado.id, item.id)
        .then(() => {
          this.snackbar.snackTextParam = 'Título desvinculado com sucesso.'
          this.snackbar.snackTypeParam = 'sucess'
          this.snackbar.showSnackParam = true
          this.titulos.splice(this.titulos.indexOf(item), 1)
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar as conciliações '
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
        .finally(() => (this.buscando = false))
    },

    executarExclusaoPagamentoConciliado() {
      PagamentoService.deletarPagamentoConciliado(this.pagamentoConciliado.id)
        .then(() => {
          this.$emit('delete', this.pagamentoConciliado)
          this.fecharDialogDetalhamento()
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao deletar o pagamento'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
    },

    fecharDialogDeletePagamentoConciliado() {
      this.showMessageError = false
      this.messageError = ''
      this.dialogDeletePagamentoConciliado = false
    },

    fecharDialogDetalhamento() {
      this.titulos = []
      this.showDialog = false
      this.$emit('update:showDialogDetalhamento', false)
    },

    abrirDialogConfirmarConciliacao(item) {
      this.dialogConfirmarConciliacao = true
      this.pagamentoConciliadoConfirmacao = item
    },

    fecharDialogConfirmarConciliacao() {
      this.showMessageError = false
      this.messageError = ''
      this.dialogConfirmarConciliacao = false
    },

    confirmarRelacionamentos() {
      PagamentoService.confirmarPagamentoConciliado(this.pagamentoConciliado.id)
        .then(() => {
          this.$emit('confirmar', this.pagamentoConciliado)
          this.fecharDialogConfirmarConciliacao()
          this.fecharDialogDetalhamento()
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar os pagamentos'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
    }
  }
}
</script>

<style scoped>
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: calc(0.4rem + 1vmin);
}
</style>
