<template>
  <v-container fluid>
    <v-card>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="d-flex align-center">
              <v-icon left> mdi-filter-variant </v-icon>
              Filtros
            </div>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-form @submit.prevent="aplicarFiltrosPagamentos">
              <v-row>
                <v-col sm="6" md="2">
                  <v-select
                    v-model="filtro.adquirente"
                    :items="itensAdquirente"
                    label="Adquirente *"
                    @change="changeFiltroAdquirente(filtro.adquirente)"
                  />
                </v-col>

                <v-col sm="6" md="3">
                  <v-text-field
                    v-model="filtro.codigoPagamento"
                    label="Código Pagamento"
                    :loading="buscando"
                    prepend-icon="mdi-numeric"
                  />
                </v-col>

                <v-col sm="12" md="7">
                  <v-select
                    v-model="filtro.empresasSelecionadas"
                    label="Empresa"
                    :loading="buscando"
                    :items="itensEmpresa"
                    multiple
                    clearable
                    prepend-icon="mdi-domain"
                  >
                    <template #prepend-item>
                      <v-list-item ripple @click="alterarSelecaoEmpresas()">
                        <v-list-item-action>
                          <v-checkbox v-model="todasEmpresasSelecionadas" />
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Selecionar Tudo </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>

                    <template #selection="{ item, index }">
                      <span v-if="index === 3" class="grey--text text-caption">
                        (+{{ filtro.empresasSelecionadas.length - 3 }} outras)
                      </span>

                      <span
                        v-else-if="
                          (index < 2 && index === filtro.empresasSelecionadas.length - 1) ||
                          index == 2
                        "
                        class="mr-1"
                      >
                        {{ `${item.text}` }}
                      </span>

                      <span v-else-if="index < 2" class="mr-1"> {{ `${item.text},` }} </span>
                    </template>
                  </v-select>
                </v-col>

                <v-col sm="6" md="6">
                  <v-text-field
                    v-model="filtro.nomeCliente"
                    label="Nome Cliente"
                    :loading="buscando"
                    prepend-icon="mdi-account"
                  />
                </v-col>

                <v-col sm="6" md="2">
                  <v-text-field
                    id="filtroValorPagamento"
                    v-model="filtro.valorPagamento"
                    label="Valor Pagamento"
                    :loading="buscando"
                    prepend-icon="mdi-currency-usd"
                    hint="Use '>' ou '<' para valores maiores/menores. (Ex: >149.99)"
                    autocomplete="off"
                  />
                </v-col>

                <v-col sm="6" md="2">
                  <v-menu
                    ref="menuDataInicial"
                    v-model="menuDataInicial"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="dataInicialFormatada"
                        label="Data Inicial"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        :loading="buscando"
                        clearable
                        @click:clear="filtroDataInicial = ''"
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      v-model="filtroDataInicial"
                      color="primary"
                      no-title
                      @input="menuDataInicial = false"
                    />
                  </v-menu>
                </v-col>

                <v-col sm="6" md="2">
                  <v-menu
                    ref="menuDataFinal"
                    v-model="menuDataFinal"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="dataFinalFormatada"
                        label="Data Final"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        :loading="buscando"
                        clearable
                        @click:clear="filtroDataFinal = ''"
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      v-model="filtroDataFinal"
                      color="primary"
                      no-title
                      @input="menuDataFinal = false"
                    />
                  </v-menu>
                </v-col>

                <v-col sm="12" md="8">
                  <v-select
                    v-model="filtro.tipoPagamento"
                    label="Tipo Pagamento"
                    :loading="buscando"
                    :items="itensTipoPagamento"
                    multiple
                  />
                </v-col>

                <v-col sm="6" md="4">
                  <v-select
                    v-model="filtro.statusConciliacao"
                    label="Status Conciliação"
                    :loading="buscando"
                    :items="itensStatusConciliacao"
                    clearable
                    prepend-icon="mdi-credit-card-outline"
                  />
                </v-col>

                <v-col sm="6" md="4">
                  <v-select
                    v-model="filtro.statusIntegracao"
                    label="Status Integração"
                    :loading="buscando"
                    :items="itensStatusIntegracao"
                    clearable
                    prepend-icon="mdi-cloud-sync-outline"
                  />
                </v-col>

                <v-col sm="6" md="4">
                  <v-select
                    v-model="filtro.divergenciaValorPagamento"
                    label="Divergência Valores"
                    :loading="buscando"
                    :items="itensDivergenciaValores"
                    clearable
                    prepend-icon="mdi-cash-multiple"
                  />
                </v-col>
              </v-row>

              <v-row justify="end">
                <v-col cols="auto">
                  <v-btn class="ml-4" color="primary" outlined type="submit" :loading="buscando">
                    <v-icon left> mdi-magnify </v-icon>
                    Aplicar
                  </v-btn>
                  <v-btn
                    class="ml-4"
                    color="normal"
                    outlined
                    type="submit"
                    :loading="buscando"
                    @click.stop.prevent="limparSelecaoDeFiltros()"
                  >
                    <v-icon left> mdi-autorenew </v-icon>
                    Limpar
                  </v-btn>
                  <v-btn
                    class="ml-4"
                    color="primary"
                    outlined
                    type="submit"
                    :disabled="buscandoXls"
                    :loading="buscandoXls"
                    @click="exportarPagamentosConciliadosXls()"
                  >
                    Exportar Arquivo Xls
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>

    <v-card class="mt-2">
      <v-container fluid>
        <v-data-table
          v-model="pagamentoConciliadoSelecionado"
          show-select
          :headers="headersPagamentos"
          :items="pagamentos"
          :loading="buscando"
          :options.sync="optionsPagamentos"
          :server-items-length="totalElements"
          loading-text="Carregando os pagamentos conciliados..."
          :footer-props="{ itemsPerPageOptions: [5, 10, 15, 30, 50], showFirstLastPage: true }"
        >
          <template #top>
            <v-container fluid class="d-flex align-center">
              <v-toolbar-title> Pagamentos Conciliados </v-toolbar-title>

              <v-spacer />

              <v-chip v-if="processoExclusao.status === 'EM_EXECUCAO'" class="mr-2">
                Exclusão de conciliações pendentes em execução...
              </v-chip>

              <v-chip v-if="processoConfirmacao.status === 'EM_EXECUCAO'" class="mr-2">
                Confirmação de conciliações pendentes em execução...
              </v-chip>

              <v-progress-circular v-if="atualizando" :size="20" width="2" indeterminate />
              <v-tooltip v-else left>
                <template #activator="{ on }">
                  <v-icon v-on="on" @click="atualizarDados()"> mdi-sync </v-icon>
                </template>

                <span> Atualizar </span>
              </v-tooltip>
            </v-container>

            <div class="text-caption">
              <v-icon dense color="green" class="ml-2"> mdi-equal </v-icon>
              <span>Valor pagamento igual ao somatório dos títulos</span>
            </div>
            <div class="mb-4 text-caption">
              <v-icon dense color="red" class="ml-2"> mdi-not-equal-variant </v-icon>
              <span>Valor pagamento diferente do somatório dos títulos</span>
            </div>
          </template>

          <template #[`body.append`]="{ headers }">
            <tr>
              <td :colspan="headers.length">
                <div class="d-flex justify-space-around">
                  <strong>
                    Total Pagamentos:
                    {{
                      sumValorPagamento.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      })
                    }}
                  </strong>
                  <strong>
                    Total Retirado da Conta:
                    {{
                      sumValorEstorno.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      })
                    }}
                  </strong>
                  <strong>
                    Total Taxa:
                    {{
                      sumValorTaxa.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      })
                    }}
                  </strong>
                  <strong>
                    Total Pagamentos Descontando Taxa:
                    {{
                      sumValorPagamentoSemTaxa.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      })
                    }}
                  </strong>
                </div>
              </td>
            </tr>
          </template>

          <template #[`item.data-table-select`]="{ item, isSelected, select }">
            <div class="mx-0 px-0">
              <v-simple-checkbox
                v-if="item.statusConciliacao !== 'CONFIRMADA'"
                :value="isSelected"
                :ripple="false"
                @input="select($event)"
              />
            </div>
          </template>

          <template #[`item.statusConciliacao`]="{ item }">
            <strong
              :class="
                item.statusConciliacao === 'CONFIRMACAO_PENDENTE'
                  ? 'amber--text text--darken-2'
                  : 'green--text text--darken-2'
              "
            >
              {{
                item.statusConciliacao === 'CONFIRMACAO_PENDENTE'
                  ? 'CONFIRMAÇÃO PENDENTE'
                  : 'CONFIRMADA'
              }}
            </strong>
          </template>

          <template #[`item.statusIntegracao`]="{ item }">
            <strong
              :class="
                item.statusIntegracao === 'PENDENTE'
                  ? 'amber--text text--darken-2'
                  : 'green--text text--darken-2'
              "
            >
              {{ item.statusIntegracao }}
            </strong>
          </template>

          <template #[`item.acoes`]="{ item }">
            <div class="d-flex flex-nowrap justify-center">
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-icon class="mr-2" v-on="on" @click="visualizarTitulos(item)">
                    mdi-file-document-multiple
                  </v-icon>
                </template>

                <span> Detalhes </span>
              </v-tooltip>

              <v-tooltip v-if="item.statusConciliacao === 'CONFIRMACAO_PENDENTE'" top>
                <template #activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    @click="abrirDialogExclusaoPagamentoConciliado(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>

                <span> Deletar </span>
              </v-tooltip>
            </div>
          </template>

          <template #[`item.pagamento.valorPagamento`]="{ item }">
            <div class="d-flex align-center">
              <span>
                {{
                  item.pagamento.valorPagamento.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  })
                }}
              </span>

              <v-tooltip top>
                <template #activator="{ on }">
                  <v-icon
                    v-if="item.divergenciaValorPagamento === 'IGUAL'"
                    dense
                    color="green"
                    class="ml-2"
                    v-on="on"
                  >
                    mdi-equal
                  </v-icon>

                  <v-icon v-else dense color="red" class="ml-2" v-on="on">
                    mdi-not-equal-variant
                  </v-icon>
                </template>

                <span>
                  {{
                    item.divergenciaValorPagamento === 'IGUAL'
                      ? 'Valor pagamento igual ao somatório dos títulos'
                      : 'Valor pagamento diferente do somatório dos títulos'
                  }}
                </span>
              </v-tooltip>
            </div>
          </template>

          <template #[`item.pagamento.valorTaxa`]="{ item }">
            {{
              item.pagamento.valorTaxa.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              })
            }}
          </template>

          <template #[`item.pagamento.dataHoraPagamento`]="{ item }">
            {{ formatDateTime(item.pagamento.dataHoraPagamento) }}
          </template>

          <template #[`item.pagamento.codigoEventoTransacao`]="{ item }">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <strong v-bind="attrs" v-on="on">{{ item.pagamento.codigoEventoTransacao }}</strong>
              </template>
              <span>{{ formatarTipoPagamento(item.pagamento.codigoEventoTransacao) }}</span>
            </v-tooltip>
          </template>
        </v-data-table>

        <v-dialog v-model="dialogExclusaoPagamento" persistent max-width="700px">
          <v-card>
            <v-card-title class="headline">Excluir</v-card-title>

            <v-card-text>
              Esta ação irá desvincular o pagamento de todos os títulos. Deseja prosseguir?
            </v-card-text>

            <v-card-text>
              <v-alert v-if="showMessageError" dense outlined type="error">
                {{ messageError }}
              </v-alert>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn color="red darken-1" text @click="fechardialogExclusaoPagamento()">
                Cancelar
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                :loading="excluindo"
                @click="executarExclusaoPagamentoConciliado()"
              >
                Confirmar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="dialogExclusaoPagamentosConciliadosPendentes"
          persistent
          max-width="700px"
        >
          <v-card>
            <v-card-title class="headline">Excluir</v-card-title>

            <v-card-text>
              Esta ação irá desvincular as conciliações pendentes filtradas. Deseja prosseguir?
            </v-card-text>

            <v-card-text>
              <v-alert v-if="showMessageError" dense outlined type="error">
                {{ messageError }}
              </v-alert>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="red darken-1"
                text
                @click="fecharDialogExclusaoPagamentosConciliadosPendentes()"
              >
                Cancelar
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                :loading="excluindo"
                @click="deletarPagamentosConciliadosPendentes()"
              >
                Confirmar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="dialogConfirmacaoPagamentosConciliadosPendentes"
          persistent
          max-width="700px"
        >
          <v-card>
            <v-card-title class="headline"> Confirmar </v-card-title>

            <v-card-text>
              Esta ação irá confirmar as conciliações pendentes filtradas. Deseja prosseguir?
            </v-card-text>

            <v-card-text>
              <v-alert v-if="showMessageError" dense outlined type="error">
                {{ messageError }}
              </v-alert>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="red darken-1"
                text
                @click="fecharDialogConfirmacaoPagamentosConciliadosPendentes()"
              >
                Cancelar
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                :loading="confirmando"
                @click="confirmarPagamentosConciliadosPendentes()"
              >
                Confirmar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogConfirmarConciliacao" persistent max-width="700px">
          <v-card>
            <v-card-title class="headline">Confirmar Conciliação</v-card-title>

            <v-card-text>
              Ao confirmar as conciliações não será possivel alterar ou deletar as mesmas. Deseja
              prosseguir?
            </v-card-text>

            <v-card-text>
              <v-alert v-if="showMessageError" dense outlined type="error">
                {{ messageError }}
              </v-alert>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn color="red darken-1" text @click="fecharDialogConfirmarConciliacao()">
                Cancelar
              </v-btn>
              <v-btn color="green darken-1" text @click="confirmarRelacionamentos()">
                Confirmar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-speed-dial v-model="menuAberto" bottom right class="mb-15" fixed>
          <template #activator>
            <v-btn v-model="menuAberto" color="primary" dark fab>
              <v-icon v-if="menuAberto"> mdi-close </v-icon>

              <v-tooltip v-else left>
                <template #activator="{ on }">
                  <v-icon v-on="on"> mdi-plus </v-icon>
                </template>

                <span>Ações com as conciliações</span>
              </v-tooltip>
            </v-btn>
          </template>

          <v-tooltip left>
            <template #activator="{ on }">
              <v-btn small fab color="primary" @click="abrirDialogConfirmarConciliacao()">
                <v-icon small v-on="on">mdi-credit-card-check-outline</v-icon>
              </v-btn>
            </template>
            <span>Confirmar Conciliação</span>
          </v-tooltip>

          <v-tooltip left>
            <template #activator="{ on }">
              <v-btn
                small
                fab
                color="primary"
                @click="dialogConfirmacaoPagamentosConciliadosPendentes = true"
              >
                <v-icon small v-on="on"> mdi-credit-card-clock-outline </v-icon>
              </v-btn>
            </template>
            <span> Confirmar Conciliações com Confirmações Pendentes </span>
          </v-tooltip>

          <v-tooltip left>
            <template #activator="{ on }">
              <v-btn
                small
                fab
                color="primary"
                @click="abrirDialogMultiExclusaoPagamentoConciliado()"
              >
                <v-icon small v-on="on"> mdi-delete </v-icon>
              </v-btn>
            </template>
            <span>Deletar Conciliação</span>
          </v-tooltip>

          <v-tooltip left>
            <template #activator="{ on }">
              <v-btn
                small
                fab
                color="primary"
                @click="dialogExclusaoPagamentosConciliadosPendentes = true"
              >
                <v-icon small v-on="on"> mdi-delete-clock </v-icon>
              </v-btn>
            </template>
            <span> Deletar Conciliações com Confirmações Pendentes </span>
          </v-tooltip>
        </v-speed-dial>
      </v-container>
    </v-card>

    <SnackbarMessage
      :show-snack.sync="snackbar.showSnackParam"
      :snack-text="snackbar.snackTextParam"
      :snack-type="snackbar.snackTypeParam"
    />

    <DetalhamentoRelacionamento
      :show-dialog-detalhamento.sync="showDialogDetalhamento"
      :pagamento-conciliado="pagamentoConciliadoParam"
      @delete="(pagamentoConciliado) => removerPagamentoConciliado(pagamentoConciliado)"
      @confirmar="(pagamentoConciliado) => atualizarPagamentoConciliado(pagamentoConciliado)"
    />
  </v-container>
</template>

<script>
import PagamentoService from '@/services/pagamento/pagamento'
import ProcessoService from '@/services/automatizacao/processo'
import EmpresaService from '@/services/organizacao/empresa'
import Snackbar from '@/models/ui/snackbar'
import SnackbarMessage from '@/components/ui/SnackbarMessage'
import DetalhamentoRelacionamento from '@/components/aplicacao/conciliacao/DetalhamentoRelacionamento.vue'
import { formatDate, formatDateTime, formataCpfCnpj } from '@/shareds/formatacoes'
import { buscaDescricaoPagamento, buscaTipoPagamentoFiltro } from '@/shareds/tipoPagamento'
import OrganizacaoService from '@/services/organizacao/organizacao'
import JSZip from 'jszip'

export default {
  components: {
    SnackbarMessage,
    DetalhamentoRelacionamento
  },

  data: () => ({
    totalElements: 0,
    optionsPagamentos: {
      page: 1,
      itensPerPage: 0,
      sortBy: []
    },
    pagamentos: [],
    sumValorPagamento: 0,
    sumValorEstorno: 0,
    sumValorTaxa: 0,
    sumValorPagamentoSemTaxa: 0,
    pagamentoConciliadoSelecionado: [],
    processoExclusao: {},
    processoConfirmacao: {},
    atualizando: false,

    headersPagamentos: [
      { text: 'Adquirente', value: 'pagamento.adquirente' },
      { text: 'Código Pagamento', value: 'pagamento.transactionId' },
      { text: 'Empresa', value: 'pagamento.empresa.nome' },
      { text: 'Nome Cliente', value: 'pagamento.nomeCliente' },
      { text: 'Email Cliente', value: 'pagamento.emailCliente' },
      { text: 'Valor Pagamento', value: 'pagamento.valorPagamento' },
      { text: 'Valor Taxa', value: 'pagamento.valorTaxa' },
      { text: 'Data Pagamento', value: 'pagamento.dataHoraPagamento' },
      { text: 'Parcela', value: 'pagamento.parcela' },
      { text: 'Tipo Pagamento', value: 'pagamento.codigoEventoTransacao' },
      { text: 'Status Conciliação', value: 'statusConciliacao', align: 'center' },
      { text: 'Status Integração', value: 'statusIntegracao' },
      { text: '', value: 'acoes', width: '1%', sortable: false }
    ],
    itensAdquirente: [
      { text: 'PayPal', value: 'PAYPAL' },
      { text: 'PagSeguro', value: 'PAGSEGURO' },
      { text: 'GetNet', value: 'GETNET' },
      { text: 'Rede', value: 'REDE' }
    ],

    itensTipoPagamento: [],
    itensEmpresa: [],

    itensStatusConciliacao: [
      { text: 'Confirmação Pendente', value: 'CONFIRMACAO_PENDENTE' },
      { text: 'Confirmada', value: 'CONFIRMADA' }
    ],

    itensStatusIntegracao: [
      { text: 'Pendente', value: 'PENDENTE' },
      { text: 'Realizada', value: 'REALIZADA' }
    ],

    itensDivergenciaValores: [
      { text: 'Pagamentos com valor igual ao somatório dos títulos', value: 'IGUAL' },
      { text: 'Pagamentos com valor diferente do somatório dos títulos', value: 'DIFERENTE' },
      { text: 'Pagamentos com valor maior que o somatório dos títulos', value: 'MAIOR' },
      { text: 'Pagamentos com valor menor que o somatório dos títulos', value: 'MENOR' }
    ],

    showDialogDetalhamento: false,
    formatDateTime: formatDateTime,
    buscando: false,
    excluindo: false,
    confirmando: false,
    pagamentoConciliadoExclusao: {},
    pagamentoConciliadoConfirmacao: {},
    snackbar: new Snackbar(),
    pagamentoConciliadoParam: {},
    showMessageError: false,
    dialogExclusaoPagamento: false,
    dialogConfirmarConciliacao: false,
    dialogExclusaoPagamentosConciliadosPendentes: false,
    dialogConfirmacaoPagamentosConciliadosPendentes: false,
    filtro: {
      tipoPagamento: [],
      empresasSelecionadas: []
    },
    filtrosAplicados: {},
    filtroDataInicial: '',
    filtroDataFinal: '',
    menuDataInicial: false,
    menuDataFinal: false,
    dataInicialFormatada: '',
    dataFinalFormatada: '',
    menuAberto: false,
    exportarPagamentosConciliados: [],
    buscandoXls: false
  }),

  computed: {
    permiteConfirmar() {
      return this.pagamentoConciliadoSelecionado.length > 0
    },
    todasEmpresasSelecionadas: {
      get() {
        return this.filtro.empresasSelecionadas.length === this.itensEmpresa.length
      },
      set(value) {
        return value
      }
    }
  },

  watch: {
    filtroDataInicial() {
      this.dataInicialFormatada = formatDate(this.filtroDataInicial)
    },

    filtroDataFinal() {
      this.dataFinalFormatada = formatDate(this.filtroDataFinal)
    },

    optionsPagamentos: {
      handler() {
        if (this.filtro.adquirente) {
          this.atualizarDados()
        }
      },

      deep: true
    }
  },

  created() {
    this.initialize()
  },

  methods: {
    async initialize() {
      await this.buscaAdquirentePadraoPelaOrganizacao()
      this.changeFiltroAdquirente(this.filtro.adquirente)
      this.buscarPagamentos()
    },

    formatarTipoPagamento(codigo) {
      //TODO - verificar se deve ser usado variável this.filtro.adquirente
      return buscaDescricaoPagamento(codigo, this.filtro.adquirente)
    },

    async atualizarDados() {
      this.atualizando = true

      try {
        await new Promise((r) => setTimeout(r, 2000))

        this.buscarProcessoExclusao()
        this.buscarProcessoConfirmacao()
        this.buscarPagamentos()
      } catch (erro) {
        this.snackbar.snackTextParam = `${erro.response.status} | ${
          erro.response.data.message === undefined
            ? erro.message
            : erro.response.data.message
            ? erro.response.data.message
            : 'Ocorreu um erro ao buscar os pagamentos'
        }`
        this.snackbar.snackTextParam = 'error'
        this.snackbar.showSnackParam = true
      } finally {
        this.atualizando = false
      }
    },

    async buscarProcessoExclusao() {
      this.processoExclusao = await ProcessoService.buscarPeloNomeEOrganizacao(
        'EXCLUSAO_CONCILIACOES_PENDENTES'
      )
    },

    async buscarProcessoConfirmacao() {
      this.processoConfirmacao = await ProcessoService.buscarPeloNomeEOrganizacao(
        'CONFIRMACAO_CONCILIACOES_PENDENTES'
      )
    },

    aplicarFiltrosPagamentos() {
      Object.assign(this.optionsPagamentos, {
        page: 1
      })
      this.buscarPagamentos()
    },

    async buscarPagamentos() {
      this.buscando = true

      const paginacao = {
        page: this.optionsPagamentos.page - 1,
        size: this.optionsPagamentos.itemsPerPage,
        sort: this.optionsPagamentos.sortBy.map(
          (sort, indice) => `${sort},${this.optionsPagamentos.sortDesc[indice] ? 'DESC' : 'ASC'}`
        )[0]
      }

      this.pagamentoConciliadoSelecionado = []
      this.pagamentoConciliadoExclusao = {}

      try {
        const filtroParams = {
          adquirente: this.filtro.adquirente || undefined,
          codigoPagamento: this.filtro.codigoPagamento || undefined,
          empresasIds:
            this.filtro.empresasSelecionadas.length > 0
              ? this.filtro.empresasSelecionadas.join(',')
              : undefined,
          nomeCliente: this.filtro.nomeCliente || undefined,
          valorPagamento: this.filtro.valorPagamento
            ? this.filtro.valorPagamento.replaceAll('.', '').replaceAll(',', '.')
            : undefined,
          dataPagamentoInicial: this.filtroDataInicial || undefined,
          dataPagamentoFinal: this.filtroDataFinal || undefined,
          statusConciliacao: this.filtro.statusConciliacao || undefined,
          statusIntegracao: this.filtro.statusIntegracao || undefined,
          divergenciaValorPagamento: this.filtro.divergenciaValorPagamento || undefined,
          tipoPagamento:
            this.filtro.tipoPagamento.length > 0 ? this.filtro.tipoPagamento.join(',') : undefined
        }

        const response = await PagamentoService.buscarPagamentosConciliados({
          ...filtroParams,
          ...paginacao
        })

        Object.assign(this.filtrosAplicados, filtroParams)

        this.totalElements = response.totalElements

        this.pagamentos = response.content.map((pagamentoConciliado) => ({
          ...pagamentoConciliado,
          isSelectable: pagamentoConciliado.statusConciliacao === 'CONFIRMACAO_PENDENTE'
        }))

        this.buscarValores(filtroParams)
      } catch (erro) {
        this.snackbar.snackTextParam = `${erro.response.status} | ${
          erro.response.data.message === undefined
            ? erro.message
            : erro.response.data.message
            ? erro.response.data.message
            : 'Ocorreu um erro ao buscar os pagamentos'
        }`
        this.snackbar.snackTextParam = 'error'
        this.snackbar.showSnackParam = true
      } finally {
        this.buscando = false
      }
    },

    buscarValores(filtros) {
      PagamentoService.buscarValoresTotaisConciliados(filtros)
        .then((response) => {
          this.sumValorPagamento = response.valorPagamento
          this.sumValorRetirada = response.valorRetirada
          this.sumValorTaxa = response.valorTaxa
          this.sumValorPagamentoSemTaxa = response.valorPagamentoSemTaxa
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar os pagamentos.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
        .finally(() => (this.buscandoValores = false))
    },

    visualizarTitulos(item) {
      this.pagamentoConciliadoParam = item
      this.showDialogDetalhamento = true
    },

    removerPagamentoConciliado(pagamentoConciliado) {
      this.snackbar.snackTextParam = 'Pagamento deletado com sucesso.'
      this.snackbar.snackTypeParam = 'sucess'
      this.snackbar.showSnackParam = true
      this.pagamentos.splice(this.pagamentos.indexOf(pagamentoConciliado), 1)
    },

    async deletarPagamentosConciliadosPendentes() {
      this.excluindo = true

      try {
        await PagamentoService.deletarPagamentoConciliadoPendentes(this.filtrosAplicados)

        this.snackbar.snackTextParam = 'Exclusão de conciliações pendentes iniciada.'
        this.snackbar.snackTypeParam = 'sucess'
        this.snackbar.showSnackParam = true

        this.atualizarDados()
        this.dialogExclusaoPagamentosConciliadosPendentes = false
      } catch (erro) {
        this.showMessageError = true
        this.messageError = `${erro.response.status} | ${
          erro.response.data.message === undefined
            ? erro.message
            : erro.response.data.message
            ? erro.response.data.message
            : 'Ocorreu um erro ao iniciar a exclusão de conciliações pendentes.'
        }`
      } finally {
        this.excluindo = false
      }
    },

    async confirmarPagamentosConciliadosPendentes() {
      this.confirmando = true

      try {
        await PagamentoService.confirmarPagamentosConciliadosPendentes(this.filtrosAplicados)

        this.snackbar.snackTextParam = 'Confirmação de conciliações pendentes iniciada.'
        this.snackbar.snackTypeParam = 'sucess'
        this.snackbar.showSnackParam = true

        this.atualizarDados()
        this.dialogConfirmacaoPagamentosConciliadosPendentes = false
      } catch (erro) {
        this.showMessageError = true
        this.messageError = `${erro.response.status} | ${
          erro.response.data.message === undefined
            ? erro.message
            : erro.response.data.message
            ? erro.response.data.message
            : 'Ocorreu um erro ao deletar o pagamento'
        }`
      } finally {
        this.confirmando = false
      }
    },

    async executarExclusaoPagamentoConciliado() {
      let pagamentoConciliadoIds

      if (!this.pagamentoConciliadoExclusao.id) {
        pagamentoConciliadoIds = this.pagamentoConciliadoSelecionado.map((item) => item.id)
      } else {
        pagamentoConciliadoIds = [this.pagamentoConciliadoExclusao.id]
      }

      try {
        this.excluindo = true

        for (let pagamentoConciliadoId of pagamentoConciliadoIds) {
          await PagamentoService.deletarPagamentoConciliado(pagamentoConciliadoId)
        }

        this.snackbar.snackTextParam =
          pagamentoConciliadoIds.length > 1
            ? 'Conciliações deletadas com sucesso.'
            : 'Conciliação deletada com sucesso.'
        this.snackbar.snackTypeParam = 'sucess'
        this.snackbar.showSnackParam = true

        this.fechardialogExclusaoPagamento()
        this.buscarPagamentos()
      } catch (erro) {
        this.showMessageError = true
        this.messageError = `${erro.response.status} | ${
          erro.response.data.message === undefined
            ? erro.message
            : erro.response.data.message
            ? erro.response.data.message
            : 'Ocorreu um erro ao deletar o pagamento'
        }`
      } finally {
        this.excluindo = false
      }
    },

    abrirDialogExclusaoPagamentoConciliado(item) {
      this.dialogExclusaoPagamento = true
      this.pagamentoConciliadoExclusao = item
      this.pagamentoConciliadoSelecionado = []
    },

    abrirDialogMultiExclusaoPagamentoConciliado() {
      if (this.pagamentoConciliadoSelecionado.length === 0) {
        this.snackbar.snackTextParam =
          'Necessário selecionar um ou mais pagamentos conciliados para excluir.'
        this.snackbar.snackTypeParam = 'error'
        this.snackbar.showSnackParam = true
        return
      }
      this.dialogExclusaoPagamento = true
      this.pagamentoConciliadoExclusao = {}
    },

    fechardialogExclusaoPagamento() {
      this.showMessageError = false
      this.messageError = ''
      this.dialogExclusaoPagamento = false
    },

    fecharDialogExclusaoPagamentosConciliadosPendentes() {
      this.showMessageError = false
      this.messageError = ''
      this.dialogExclusaoPagamentosConciliadosPendentes = false
    },

    fecharDialogConfirmacaoPagamentosConciliadosPendentes() {
      this.showMessageError = false
      this.messageError = ''
      this.dialogConfirmacaoPagamentosConciliadosPendentes = false
    },

    abrirDialogConfirmarConciliacao(item) {
      if (this.pagamentoConciliadoSelecionado.length === 0) {
        this.snackbar.snackTextParam =
          'Necessário selecionar um ou mais pagamentos conciliados para confirmar.'
        this.snackbar.snackTypeParam = 'error'
        this.snackbar.showSnackParam = true
        return
      }

      this.dialogConfirmarConciliacao = true
      this.pagamentoConciliadoConfirmacao = item
    },

    fecharDialogConfirmarConciliacao() {
      this.showMessageError = false
      this.messageError = ''
      this.dialogConfirmarConciliacao = false
    },

    confirmarRelacionamentos() {
      const pagamentoConciliadoIds = this.pagamentoConciliadoSelecionado.map(
        (pagamento) => pagamento.id
      )

      PagamentoService.confirmarPagamentosConciliados(pagamentoConciliadoIds)
        .then(() => {
          this.snackbar.snackTextParam = 'Os pagamentos conciliados foram confirmados com sucesso.'
          this.snackbar.snackTypeParam = 'sucess'
          this.snackbar.showSnackParam = true
          this.pagamentoConciliadoSelecionado = []
          this.fecharDialogConfirmarConciliacao()
          this.buscarPagamentos()
        })
        .catch((erro) => {
          this.showMessageError = true
          this.messageError = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao confirmar os pagamentos conciliados.'
          }`
        })
    },

    atualizarPagamentoConciliado(pagamentoConciliado) {
      this.snackbar.snackTextParam = 'Conciliação confirmada com sucesso.'
      this.snackbar.snackTypeParam = 'sucess'
      this.snackbar.showSnackParam = true

      const index = this.pagamentos.indexOf(pagamentoConciliado)

      Object.assign(this.pagamentos[index], {
        statusConciliacao: 'CONFIRMADA'
      })
    },

    async limparSelecaoDeFiltros() {
      this.filtro = {
        tipoPagamento: [],
        adquirente: this.itensAdquirente[0].value,
        empresasSelecionadas: []
      }

      await this.buscaAdquirentePadraoPelaOrganizacao()

      this.filtroDataInicial = ''
      this.filtroDataFinal = ''
      this.optionsPagamentos = {
        page: 1,
        sortBy: [],
        itensPerPage: 0
      }

      this.changeFiltroAdquirente(this.filtro.adquirente)
      this.buscarPagamentos()
    },

    changeFiltroAdquirente(adquirente) {
      this.itensTipoPagamento = buscaTipoPagamentoFiltro(adquirente)
      this.buscarItensEmpresa(adquirente)
    },

    buscarItensEmpresa(adquirente) {
      this.itensEmpresa = []
      this.filtro.empresasSelecionadas = []

      EmpresaService.buscarTudo({ adquirente })
        .then((response) => {
          this.itensEmpresa = response.map((item) => ({
            text: `${formataCpfCnpj(item.documento)} - ${item.nome}`,
            value: item.id
          }))
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar os empresas.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
        .finally(() => (this.buscandoEmpresa = false))
    },

    alterarSelecaoEmpresas() {
      this.$nextTick(() => {
        if (this.todasEmpresasSelecionadas) {
          this.filtro.empresasSelecionadas = []
        } else {
          this.filtro.empresasSelecionadas = this.itensEmpresa.slice().map((item) => item.value)
        }
      })
    },

    async buscaAdquirentePadraoPelaOrganizacao() {
      await OrganizacaoService.buscaAdquirentePadrao()
        .then((response) => {
          this.filtro.adquirente = response
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar o adquirente.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
    },

    exportarPagamentosConciliadosXls() {
      this.buscandoXls = true
      const filtroParams = {
        adquirente: this.filtro.adquirente || undefined,
        codigoPagamento: this.filtro.codigoPagamento || undefined,
        nomeCliente: this.filtro.nomeCliente || undefined,
        valorPagamento: this.filtro.valorPagamento
          ? this.filtro.valorPagamento.replaceAll('.', '').replaceAll(',', '.')
          : undefined,
        dataPagamentoInicial: this.filtroDataInicial || undefined,
        dataPagamentoFinal: this.filtroDataFinal || undefined,
        statusConciliacao: this.filtro.statusConciliacao || undefined,
        divergenciaValorPagamento: this.filtro.divergenciaValorPagamento || undefined,
        statusIntegracao: this.filtro.statusIntegracao || undefined,
        tipoPagamento:
          this.filtro.tipoPagamento.length > 0 ? this.filtro.tipoPagamento.join(',') : undefined
      }
      PagamentoService.exportarConciliacao(filtroParams)
        .then((response) => {
          this.exportarPagamentosConciliados = response
          this.downloadzip()
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar os Pagamentos Conciliados.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
        .finally(() => (this.buscandoValores = false))
    },

    downloadzip() {
      let zip = new JSZip()
      zip.file('pagamentosConciliaos' + '.xls', this.exportarPagamentosConciliados)

      zip
        .generateAsync({
          type: 'base64'
        })
        .then(function (content) {
          var link = document.createElement('a')
          link.href = 'data:application/zip;base64,' + content
          link.download = 'pagamentosConciliados.zip'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
        .finally(() => (this.buscandoXls = false))
    }
  }
}
</script>

<style scoped>
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: calc(0.4rem + 1vmin);
}

::v-deep .v-data-table-header {
  vertical-align: top;
}
</style>
